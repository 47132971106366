import { Tabs, TabsProps } from "antd";
import { CreateButton, List } from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DocsTabs } from "./DocsTabs";
import { SubsectionsList } from "./SubsectionsList";

export function DocsPage() {
  const navigate = useNavigate();
  const { push } = useNavigation();

  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get("tab") || "docsList";

  const onChange = (key: string) => {
    navigate(
      {
        search: createSearchParams({
          tab: key,
        }).toString(),
      },
      {
        replace: true,
      }
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "docsList",
      label: "Документы",
      children: <DocsTabs />,
    },
    {
      key: "SubsectionsList",
      label: "Подразделы",
      children: <SubsectionsList />,
    },
  ];

  const handleNavigateToCreateSubsectionDocs = () => {
    push("/league-info/docs/subsection-docs-create");
  };

  const handleNavigateToCreate = () => {
    push("/league-info/docs/create");
  };

  return (
    <List
      title="Документы"
      resource={"docs"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <>
          <CreateButton onClick={() => handleNavigateToCreateSubsectionDocs()}>
            Добавить подраздел
          </CreateButton>
          <CreateButton onClick={() => handleNavigateToCreate()}>
            Добавить документ
          </CreateButton>
        </>
      }
    >
      <Tabs items={items} onChange={onChange} activeKey={tabKey} />
    </List>
  );
}
