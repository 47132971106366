import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";

import { PartnersShow } from "../Partners-Show/Partners-Show";
import { PartnersTabs } from "../Partners-List/Partners-Tabs";

const partnersRoutes = [
  <Route
    key={"partners"}
    path="partners"
    element={
      <ShowProvider>
        <PartnersTabs />
      </ShowProvider>
    }
  />,
  <Route
    key={"partners-show"}
    path="partners/:id"
    element={
      <ShowProvider>
        <PartnersShow />
      </ShowProvider>
    }
  />,
  <Route
    key={"partners-create"}
    path="partners/create"
    element={
      <ShowProvider>
        <PartnersShow />
      </ShowProvider>
    }
  />,
];

export const PARTNERS_ROUTES = partnersRoutes.map((route) => route);
