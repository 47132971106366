import { Show, useForm } from "@refinedev/antd";
import { Button, Col, DatePicker, Form, Input, Row, Space, Upload } from "antd";
import { v4 as randomId } from "uuid";

import {
  errorNotification,
  s3Path,
  successNotification,
} from "../../../helpers";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { SubsectionDocType } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";
import { useNavigation } from "@refinedev/core";
import axAPI from "api/axios-instance";
import { useContext, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useMatch } from "react-router-dom";
import { EnvContext } from "providers";

export const PresentationsShow = () => {
  const route = useMatch("/league-info/presentations/show/:id");
  const id = Number(route?.params.id);
  const { push } = useNavigation();
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const { THUMBOR_URL } = useContext(EnvContext);

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<SubsectionDocType>({
      resource: "presentations",
      action: "edit",
      id,
      meta: { id, fileUrl: fileUrl },
      dataProviderName: "presentationsDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "презентация успешно добавлена",
          type: "success",
        },
        callback: () => push("/league-info/presentations"),
      }),
      ...errorNotification({}),
    });

  const watchFile = form.getFieldValue("file");
  if (typeof watchFile == "string" && !fileUrl) {
    setFileUrl(watchFile);
  }

  async function uploadFile(file: File, type: string) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const result = await axAPI.post("/cms/image/upload/type", formData, {
        params: { type },
      });
      return Promise.resolve({ s3id: result.data });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return (
    <Show
      title={<AppTitle canGoBack>Добавление презентации</AppTitle>}
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"title"}
              label={"Наименование:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо наименование",
                },
                {
                  max: 100,
                  min: 3,
                  message: "Не более 100 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите наименование "}></Input>
            </Form.Item>

            <Form.Item
              name={"ord"}
              label={"Порядок отображения"}
              style={{ maxWidth: "500px", width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать порядок отображения ",
                },
              ]}
            >
              <Input
                placeholder={"Укажите порядок отображения"}
                type="number"
              ></Input>
            </Form.Item>
            <Form.Item
              name={"date"}
              label={"Дата"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать дату",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={"DD.MM.YYYY"}
                style={{ width: "100%" }}
                placeholder={"Дата"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"icon"}>
              <AppImageUploader
                type="DOCUMENTS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать фото-обложку",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Фото-обложка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"file"}
              label={"Файл:"}
              style={{ maxWidth: "500px", width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Загрузите иконку",
                },
              ]}
            >
              <Upload
                customRequest={(e: any) => {
                  setIsLoadingFile(true);
                  uploadFile(e.file as File, "DOCUMENTS")
                    .then((res) => {
                      setFileUrl(res.s3id);
                      e.onSuccess("ok");
                    })
                    .finally(() => setIsLoadingFile(false));
                }}
                accept=".pdf"
                fileList={fileList}
                onChange={({ fileList }) => {
                  setFileList(fileList);
                  if (!fileList.length) setFileUrl("");
                }}
                previewFile={async () => await ""}
                itemRender={() => ""}
              >
                <Button disabled={isLoadingFile} icon={<UploadOutlined />}>
                  {isLoadingFile ? "Loading..." : "Click to Upload"}
                </Button>
              </Upload>
              <a
                href={s3Path({
                  path: fileUrl,
                  original: true,
                  thumbor: THUMBOR_URL,
                })}
                target="_blank"
                rel="noreferrer"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {fileUrl.replace(/.*\//, "")}
              </a>
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"} disabled={!fileUrl}>
              {"Сохранить"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
