import { Show, useForm } from "@refinedev/antd";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
} from "antd";

import { errorNotification, successNotification } from "../../../helpers";
import { AppTitle } from "../../../components";
import { CustomNotificationType, GroupInfoType, TeamType } from "../Lib";
import { useCustom } from "@refinedev/core";
import axAPI from "api/axios-instance";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { TextAreaRef } from "antd/es/input/TextArea";
import { SmileOutlined } from "@ant-design/icons";

export const PushNotificationContent = () => {
  const [teams, setTeams] = useState<TeamType[]>([]);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [cursorPositionTitle, setCursorPositionTitle] = useState<number>(0);
  const [emojiMessageOpen, setEmojiMessageOpen] = useState<boolean>(false);
  const [emojiTittleOpen, setEmojiTittleOpen] = useState<boolean>(false);
  const { data, isLoading } = useCustom({
    dataProviderName: "pushNotificationDataProvider",
    url: "team",
    method: "get",
  });

  const { formProps, formLoading, form } = useForm<CustomNotificationType>({
    resource: "push-notification",
    action: "create",
    dataProviderName: "pushNotificationDataProvider",
    redirect: "list",
    ...successNotification({
      successData: {
        message: "Push-уведомлениe успешно отправлено",
        type: "success",
      },
      callback: () => form.resetFields(),
    }),
    ...errorNotification({}),
  });

  const leagueId = useWatch("leagueId", form);
  const groupId = useWatch("groupId", form);
  const messageInputRef = useRef<TextAreaRef>(null);
  const titleInputRef = useRef<InputRef>(null);

  function updateCursorPosition() {
    const inputElement = messageInputRef?.current?.resizableTextArea?.textArea;
    if (inputElement?.selectionStart || inputElement?.selectionStart === 0) {
      setCursorPosition(Number(inputElement.selectionStart));
    }
  }
  function updateCursorPositionTitle() {
    const inputElement = titleInputRef?.current?.input;
    if (inputElement?.selectionStart || inputElement?.selectionStart === 0) {
      setCursorPositionTitle(Number(inputElement.selectionStart));
    }
  }

  function onEmojiClickTitle(emojiData: EmojiClickData) {
    const inputElement = titleInputRef?.current?.input;
    const index = inputElement?.selectionStart || cursorPositionTitle;

    let leftPart, rightPart;
    if (index == 0) {
      leftPart = "";
      rightPart = inputElement?.value;
    } else {
      leftPart = inputElement?.value.slice(0, index);
      rightPart = inputElement?.value.slice(index);
    }

    inputElement?.setSelectionRange(index + emojiData.emoji.length, null);
    setCursorPositionTitle(Number(index + emojiData.emoji.length));
    const newMessage = `${leftPart}${emojiData.emoji}${rightPart}`;
    console.log("newMessage", newMessage);
    form.setFieldValue("title", newMessage);
  }

  function onEmojiClick(emojiData: EmojiClickData) {
    const inputElement = messageInputRef?.current?.resizableTextArea?.textArea;
    const index = inputElement?.selectionStart || cursorPosition;

    let leftPart, rightPart;
    if (index == 0) {
      leftPart = "";
      rightPart = inputElement?.value;
    } else {
      leftPart = inputElement?.value.slice(0, index);
      rightPart = inputElement?.value.slice(index);
    }

    inputElement?.setSelectionRange(index + emojiData.emoji.length, null);
    setCursorPosition(Number(index + emojiData.emoji.length));
    const newMessage = `${leftPart}${emojiData.emoji}${rightPart}`;
    form.setFieldValue("message", newMessage);
  }

  async function loadTeams(leagueId: number, groupId?: number) {
    if (leagueId) {
      let seasonId;
      switch (leagueId) {
        case 100:
          seasonId = data?.data.activeSeasonInfo.activeSeasonMelbet[0].seasonId;
          break;
        case 200:
          seasonId = data?.data.activeSeasonInfo.activeSeasonLeonA[0].seasonId;
          break;
        case 300:
          seasonId = data?.data.activeSeasonInfo.activeSeasonLeonB[0].seasonId;
          break;
      }
      const { data: teams } = await axAPI.get("cms/info/teamsBySeason", {
        params: { leagueId, seasonId, groupId },
      });
      return teams;
    } else {
      return [];
    }
  }

  useEffect(() => {
    loadTeams(leagueId, groupId).then((teams) => {
      setTeams(teams);
    });
  }, [leagueId, groupId]);

  return (
    <Show
      isLoading={formLoading || isLoading}
      title={
        <AppTitle isLoading={formLoading || isLoading}>
          {"Отправка push-уведомления"}
        </AppTitle>
      }
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form
        {...formProps}
        layout={"vertical"}
        initialValues={{ leagueId: null, teamId: null }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Flex align="center" gap={5} justify="space-between">
              <Form.Item
                name={"title"}
                label="Заголовок"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Необходимо указать заголовок" },
                  {
                    max: 120,
                    min: 3,
                    message: "Не более 120 символов и не менее 3 символов",
                  },
                ]}
              >
                <Input
                  ref={titleInputRef}
                  onInput={updateCursorPositionTitle}
                  onBlur={updateCursorPositionTitle}
                  placeholder={"Укажите название"}
                ></Input>
              </Form.Item>
              <Button
                onClick={() => {
                  setEmojiMessageOpen(false);
                  setEmojiTittleOpen(!emojiTittleOpen);
                }}
                type="text"
              >
                <SmileOutlined />
              </Button>
            </Flex>
            <Flex align="center" gap={5} justify="space-between">
              <Form.Item
                name={"message"}
                label={"Сообщение:"}
                style={{ width: "100%" }}
                rules={[
                  { max: 1500, message: "Не более 1500 символов " },
                  { required: true, message: "Необходимо указать сообщение" },
                ]}
              >
                <Input.TextArea
                  ref={messageInputRef}
                  rows={20}
                  placeholder={"Укажите сообщение"}
                  onInput={updateCursorPosition}
                  onBlur={updateCursorPosition}
                ></Input.TextArea>
              </Form.Item>
              <Button
                onClick={() => {
                  setEmojiTittleOpen(false);
                  setEmojiMessageOpen(!emojiMessageOpen);
                }}
                type="text"
              >
                <SmileOutlined />
              </Button>
            </Flex>
            <Form.Item name="leagueId" label={"Лига"}>
              <Select placeholder="Выберите лигу">
                <Select.Option key={null} value={null}>
                  ФНЛ (все лиги)
                </Select.Option>
                <Select.Option key={100} value={100}>
                  Мелбет - Первая лига
                </Select.Option>
                <Select.Option key={200} value={200}>
                  LEON - Вторая лига А
                </Select.Option>
                <Select.Option key={300} value={300}>
                  LEON - Вторая лига Б
                </Select.Option>
              </Select>
            </Form.Item>
            {(leagueId == 200 || leagueId == 300) && (
              <Form.Item name="groupId" label={"Группа"}>
                <Select placeholder="Выберите группу">
                  <Select.Option key={null} value={null}>
                    Все группы
                  </Select.Option>
                  {leagueId == 200 &&
                    data?.data.groupInfo.groupInfoLeonA.map(
                      (groupInfo: GroupInfoType) => (
                        <Select.Option key={groupInfo.id} value={groupInfo.id}>
                          {groupInfo.name}
                        </Select.Option>
                      )
                    )}
                  {leagueId == 300 &&
                    data?.data.groupInfo.groupInfoLeonB.map(
                      (groupInfo: GroupInfoType) => (
                        <Select.Option key={groupInfo.id} value={groupInfo.id}>
                          {groupInfo.name}
                        </Select.Option>
                      )
                    )}
                </Select>
              </Form.Item>
            )}
            {leagueId && (
              <Form.Item name="teamId" label={"Клуб"}>
                <Select placeholder="Выберите группу">
                  <Select.Option key={null} value={null}>
                    Все клубы
                  </Select.Option>
                  {teams.map((team: TeamType) => (
                    <Select.Option key={team.teamId} value={team.teamId}>
                      {team.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Space>
              <Button type={"primary"} htmlType={"submit"}>
                {"Отправить"}
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <EmojiPicker
              style={{ marginTop: "150px" }}
              open={emojiMessageOpen}
              onEmojiClick={(EmojiClickData) => onEmojiClick(EmojiClickData)}
            />
            <EmojiPicker
              open={emojiTittleOpen}
              onEmojiClick={(EmojiClickData) =>
                onEmojiClickTitle(EmojiClickData)
              }
            />
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
