import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { LeagueStaffType } from "../Lib";
import dayjs from "dayjs";
import { recursiveUprateOrd } from "../Lib/helpers";
import { dateFormat } from "helpers";

export const employeesTeamDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/staff";

      try {
        const { data } = await httpClient.get(url);
        const result = data.filter((employee: LeagueStaffType) =>
          employee.departments.some(
            (department) => department.departmentId != -1
          )
        );
        return {
          data: result,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/staff/person";

      try {
        const { data: response } = await httpClient.get(url, {
          params: { staffId: id },
        });
        const data = await {
          ...response,
          teamDay: response.teamDay && dayjs(response.teamDay),
          birthday: response.birthday && dayjs(response.birthday),
        };
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      meta: {
        deptData: any;
      };
      variables: any;
    }) => {
      const { deptData } = meta;
      try {
        const res = await httpClient.post("cms/league/staff", {
          ...variables,
          ord: Number(variables.ord),
          teamDay: dateFormat(variables.teamDay, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          birthday: dateFormat(
            variables.birthday,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        if (variables.departmentId) {
          const dept = deptData.find(
            (dept: any) => dept.id == variables.departmentId
          );
          if (!variables.ord) {
            const ordArr = dept.staff.map((employee: any) => employee.ord);
            const maxOrd =
              ordArr.sort((a: any, b: any) => a - b)[ordArr.length - 1] || 1;
            variables.ord = maxOrd;
          }
          await httpClient.post("cms/league/staff/dept", {
            departmentId: variables.departmentId,
            staffId: res.data.id,
            ord: Number(variables.ord),
          });

          await recursiveUprateOrd(
            variables.ord,
            dept.staff,
            async (data) =>
              await httpClient.post("cms/league/staff/dept", {
                departmentId: dept.id,
                staffId: data.staffId,
                ord: data.ord,
              })
          );
        }

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
        deptData: any[];
        oldDeptId: number;
      };
      variables: any;
    }) => {
      const { id, deptData, oldDeptId } = meta;

      const url = "cms/league/staff";

      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          id,
          ord: Number(variables.ord),
          teamDay: dateFormat(variables.teamDay, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          birthday: dateFormat(
            variables.birthday,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        if (
          variables.departmentId &&
          oldDeptId &&
          oldDeptId != variables.departmentId
        ) {
          const oldDept = deptData.find((dept) => dept.id == oldDeptId);
          const ord = oldDept.staff.find(
            (employee: any) => employee.staffId == id
          ).ord;
          await httpClient.delete("cms/league/staff/dept", {
            data: {
              departmentId: oldDept.id,
              staffId: id,
              ord: ord,
            },
          });
        }

        if (variables.departmentId) {
          const dept = deptData.find(
            (dept: any) => dept.id == variables.departmentId
          );
          if (!variables.ord) {
            const ordArr = dept.staff.map((employee: any) => employee.ord);
            const maxOrd =
              ordArr.sort((a: any, b: any) => a - b)[ordArr.length - 1] || 1;
            variables.ord = maxOrd;
          }

          await httpClient.post("cms/league/staff/dept", {
            departmentId: variables.departmentId,
            staffId: id,
            ord: Number(variables.ord),
          });

          const isOrdBusy = dept.staff.some(
            (person: any) =>
              person.ord === Number(variables.ord) && person.staffId != id
          );

          if (isOrdBusy) {
            const staff = dept.staff.filter(
              (person: any) => person.staffId != id
            );
            await recursiveUprateOrd(
              variables.ord,
              staff,
              async (data) =>
                await httpClient.post("cms/league/staff/dept", {
                  departmentId: dept.id,
                  staffId: data.staffId,
                  ord: data.ord,
                })
            );
          }
        }

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/staff";
      try {
        const { data } = await httpClient.delete(url, {
          params: { staffId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    custom: async ({ meta, url }) => {
      try {
        const { data } = await httpClient.get("cms/league/dept", {
          params: { departmentType: "TEAM" },
        });
        return {
          data: data?.childDept || [],
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
  };
