import CropperImage, { CropperImageProps } from "antd-cropper-img";
import React, { FC, useContext, useEffect, useState } from "react";
import { message, Spin, Upload, UploadFile, UploadProps, Modal } from "antd";
import s from "./app-image-uploader.module.scss";

import { RcFile } from "antd/lib/upload";
import { useCreate } from "@refinedev/core";
import { EnvContext } from "../../providers";
import { errorNotification, successNotification } from "../../helpers";
import { s3Path } from "../../helpers/images.helper";

export enum EFileTypes {
  NEWS = "NEWS",
  MATCHES = "MATCHES",
  CLUBS = "CLUBS",
  PARTNERS = "PARTNERS",
  CLUBS_BG = "CLUBS_BG",
  BROADCAST = "BROADCAST",
  STAFF = "STAFF",
  DOCUMENTS = "DOCUMENTS",
}

interface IAppFileUploaderProps {
  cropperProps?: Partial<CropperImageProps>;
  uploadProps?: Partial<UploadProps>;
  disabled?: boolean;
  imgHeight?: number;
  imgWidth?: number;
  entityId?: string;
  fileReset?: boolean;
  noBeforeCropper?: boolean;
  setDisabled?: (a: boolean) => void;
  serviceType: EFileTypes;
  type:
    | "NEWS"
    | "MATCH"
    | "TEAM"
    | "PARTNERS"
    | "STADIUM"
    | "BROADCAST"
    | "DOCUMENTS"
    | "STAFF";
  maxFileSizeMB?: number;
}

export const AppImageUploader: FC<IAppFileUploaderProps> = ({
  cropperProps,
  uploadProps,
  imgHeight,
  imgWidth,
  entityId,
  fileReset,
  serviceType,
  noBeforeCropper = false,
  setDisabled = (value: boolean) => null,
  disabled,
  type,
  maxFileSizeMB = 5,
  ...props
}) => {
  const { value, onChange } = props as any;
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [previewFile, setPreviewFile] = useState<string>("");
  const { mutate } = useCreate();
  const [uploading, setUploading] = useState(false);
  const { THUMBOR_URL } = useContext(EnvContext);

  useEffect(() => {
    if (fileReset) {
      setFiles([]);
    }
  }, [fileReset]);

  useEffect(() => {
    if (value) {
      const s3Url = s3Path({
        thumbor: THUMBOR_URL,
        original: true,
        path: value,
      });
      setTimeout(() => {
        setFiles([
          {
            url: s3Url,
            name: uploadProps?.children as string,
            uid: Math.random().toString(),
          },
        ]);
      }, 1000);
    } else {
      setFiles([]);
    }
  }, [value]);

  const validateDimensions = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        if (imgHeight && imgWidth) {
          const { width, height } = image;
          if (width >= imgWidth && height >= imgHeight) {
            resolve("");
          } else {
            reject(
              `Разрешение должно быть больше чем ${imgWidth} на ${imgHeight} `
            );
            setUploading(false);
            setDisabled(false);
          }
        } else {
          resolve("");
          setUploading(false);
          setDisabled(false);
        }
      };
    });
  };

  const validateFileSize = (file: File, maxSizeMB: number) => {
    //валидация при загрузке изображения
    return new Promise<string>((resolve, reject) => {
      const fileSizeInMB = file.size / 1024 / 1024; // Перевод размера файла в мегабайты
      if (fileSizeInMB > maxSizeMB) {
        reject(`Размер файла не должен превышать ${maxSizeMB} MB`);
      } else {
        resolve("");
        setUploading(false);
        setDisabled(false);
      }
    });
  };

  const validateType = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
          "image/bmp",
          "image/wbmp",
        ].includes(file.type)
      ) {
        reject(
          "Изображение может быть только .png,.jpeg,.webp,.bmp,.webp формата"
        );
        setUploading(false);
        setDisabled(false);
      } else {
        resolve("");
        setUploading(false);
        setDisabled(false);
      }
    });
  };
  const onBeforeCrop = async (file: File) => {
    try {
      await validateType(file);
      await validateDimensions(file);
      await validateFileSize(file, maxFileSizeMB);
      message.success("Файл успешно загружен");
      return true;
    } catch (error: any) {
      message.error(error);
      return false;
    }
  };

  const onUpdateForm = (file: string) => {
    onChange(!file ? undefined : file);
  };

  // Функция сжимающего изображения, нужна тк cropper увеличивает размер файла
  function resizeImageFile(file: RcFile, maxSizeMB = 5): Promise<RcFile> {
    const maxSizeBytes = maxSizeMB * 1024 * 1024;

    return new Promise((resolve, reject) => {
      if (file.size <= maxSizeBytes) {
        resolve(file);
        return;
      }

      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          // Создаем Canvas для изменения размеров изображения
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (!ctx) {
            reject(new Error("Ошибка создания контекста Canvas."));
            return;
          }

          // Определяем коэффициент масштабирования
          const scaleFactor = Math.sqrt(maxSizeBytes / file.size);
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          // Рисуем изображение на Canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Конвертируем Canvas в Blob и создаем новый объект RcFile
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Ошибка создания Blob из Canvas."));
                return;
              }

              // Создаем новый объект RcFile
              const resizedRcFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              }) as RcFile;

              // Добавляем uid из исходного RcFile
              resizedRcFile.uid = file.uid;

              resolve(resizedRcFile);
            },
            file.type,
            0.7
          ); // Качество сжатия 0.7 (можно настроить)
        };
        img.src = event?.target?.result as string;
      };

      reader.onerror = function () {
        reject(new Error("Ошибка чтения файла."));
      };

      reader.readAsDataURL(file);
    });
  }

  const onBeforeUpload = async (file: RcFile) => {
    await validateType(file);
    await validateDimensions(file);
    await resizeImageFile(file).then((resizedFile) => {
      setUploading(true);
      setDisabled(true);

      mutate({
        dataProviderName: "uploadDataProvider",
        values: {
          entityId,
          serviceType,
          blob: resizedFile,
          type,
        },
        ...errorNotification({
          errorData: {
            callback() {
              setUploading(false);
              setDisabled(false);
            },
          },
        }),
        ...successNotification({
          callback: (data: { s3id: string }) => {
            const resultFile = [
              ...files,
              { ...resizedFile, originFileObj: resizedFile },
            ];
            setUploading(false);
            setDisabled(false);
            setFiles(resultFile);
            data && onUpdateForm(data.s3id);
          },
          successData: {
            message: "Файл успешно загружен",
            type: "success",
          },
        }),
        resource: "",
      });
    });
  };
  const dummyRequest = (action: any) => {
    setTimeout(() => {
      action.onSuccess("ok");
    });
  };

  const onRemove = (file: UploadFile) => {
    const clearData = files.filter((stateFile) => stateFile.uid !== file.uid);
    setFiles(clearData);
    onUpdateForm("");
  };
  const showPreview = (file: any) => {
    setPreviewFile(file.thumbUrl || file.url);
  };

  return (
    <div className={s.uploaderContainer}>
      {uploading && (
        <div className={s.uploaderLoading}>
          <Spin />
        </div>
      )}
      <CropperImage
        dragMode={"move"}
        maxZoom={10}
        minZoom={0}
        aspect={NaN}
        minContainerHeight={200}
        minContainerWidth={200}
        zoomable
        rotatable={false}
        movable
        cropBoxMovable
        cropBoxResizable={true}
        beforeCrop={onBeforeCrop}
        {...cropperProps}
      >
        {/*  */}
        <Upload
          disabled={disabled}
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.webp,.bmp,.wbmp"
          onPreview={showPreview}
          fileList={files}
          beforeUpload={onBeforeUpload}
          onRemove={onRemove}
          className={s.fileUpload}
          {...uploadProps}
        >
          {files?.length < 1 && uploadProps?.children}
        </Upload>
      </CropperImage>
      <Modal
        title="Добавленный файл"
        open={Boolean(previewFile)}
        onCancel={() => setPreviewFile("")}
        footer={<></>}
        width={500}
      >
        <div className={s.previewImage}>
          <img src={previewFile} alt="" />
        </div>
      </Modal>
    </div>
  );
};
