import {
  Card,
  Form,
  FormInstance,
  message,
  Space,
  Typography,
  Tooltip,
  Input,
  Radio,
} from "antd";
import {
  AppImageUploader,
  AppRichText,
  EFileTypes,
  richTextTemplate,
} from "../../../../components";
import { v4 as randomId } from "uuid";
import {
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import s from "./Club-Management-Create.module.scss";
import { FC } from "react";
import { useWatch } from "antd/es/form/Form";
const { Paragraph } = Typography;

type NewsBlockCreateProps = {
  setDisabled?: boolean;
  form: FormInstance<any>;
};

export const ClubManagementBlockCreate: FC<NewsBlockCreateProps> = ({
  setDisabled,
  form,
}) => {
  const watchBlocks = useWatch("content", form);

  const hideBlockAdd = Boolean(watchBlocks?.length > 9) || setDisabled;

  return (
    <Space size={16} direction="vertical" style={{ width: "100%" }}>
      <Paragraph className={s.newsBlockUpperHeader}>
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          {<span className={s.requiredStar}>*</span>}
          Блоки{" "}
          <Tooltip title="Максимального количество блоков - 10">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Paragraph>
      <Form.List
        name="content"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                message.error("Должен быть хотя бы один блок");
                return Promise.reject(
                  new Error("Должен быть хотя бы один блок")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
            {fields.map((field, index, value) => {
              const filedData = watchBlocks?.[field.name];
              return (
                <Card
                  key={field.key}
                  title={
                    <div className={s.newsBlockHeader}>
                      {`Блок ${index + 1}`}
                      {!setDisabled && (
                        <div
                          className={s.appImageDelete}
                          onClick={(event) => {
                            remove(field.name);
                          }}
                        >
                          <DeleteOutlined
                            style={{
                              color: "var(--danger)",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  <Form.Item
                    label="Заголовок"
                    rules={[
                      { required: true, message: "Укажите заголовок" },
                      {
                        min: 5,
                        max: 100,
                        message: "Заголовок может быть от 5 до 100 символов",
                      },
                    ]}
                    name={[field.name, "title"]}
                  >
                    <Input
                      disabled={setDisabled}
                      type="text"
                      placeholder={"Укажите заголовок"}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "media", "type"]}
                    label={"Тип медиа"}
                  >
                    <Radio.Group disabled={setDisabled}>
                      <Radio value={"PHOTO"}>Фото</Radio>
                      <Radio value={"LINK"}>Ссылка на видео</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "media", "url"]}
                    label={"Медиа"}
                  >
                    {filedData?.media?.type == "PHOTO" ? (
                      <AppImageUploader
                        type="NEWS"
                        disabled={setDisabled}
                        entityId={`${randomId()}`}
                        serviceType={EFileTypes.NEWS}
                        cropperProps={{
                          modalTitle: "Редактировать фото",
                        }}
                        uploadProps={{
                          multiple: false,
                          children: "Фото блока",
                          listType: "picture-card",
                        }}
                      />
                    ) : filedData?.media?.type == "LINK" ? (
                      <Input
                        disabled={setDisabled}
                        placeholder={"Укажите ссылку на видео"}
                      ></Input>
                    ) : (
                      <span style={{ color: "red" }}>Не выбран тип медиа</span>
                    )}
                  </Form.Item>

                  <Form.Item name={[field.name, "text"]} label={"Контент"}>
                    <AppRichText
                      readonly={setDisabled}
                      editorTemplate={richTextTemplate.backofficeNews}
                      richTextProps={{
                        placeholder: "Укажите контент",
                        setOptions: {
                          buttonList: richTextTemplate.backofficeNews,
                          font: ["TT Norms Pro", "TT Runs"],
                          colorList: [
                            "#FFFFFF",
                            "#F6F6F6",
                            "#9EA8B4",
                            "#5D636A",
                          ],
                          linkProtocol: "https://",
                          linkRelDefault: {
                            default: "no-follow",
                          },
                          defaultStyle:
                            "font-family: TT Norms Pro, sans-serif; background-color: #383838; color: #FFFFFF; font-size: 16px;",
                          fontSize: [
                            10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                          ],
                        },
                      }}
                    />
                  </Form.Item>
                </Card>
              );
            })}
            {!hideBlockAdd && (
              <div className={s.addImageContainer} onClick={() => add()}>
                <PlusOutlined />
                Добавить блок
              </div>
            )}
          </Space>
        )}
      </Form.List>
    </Space>
  );
};
