import { Show, useForm } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../helpers";

import { Form, Row, Space, Button } from "antd";
import { ClubManagementCreateRequest } from "../Lib/types";
import { ClubManagementFileCreate } from "./Club-Management-Create/Club-Management-File-Create";

export const ClubManagementDocsList = () => {
  const { formProps, form, formLoading, onFinish } =
    useForm<ClubManagementCreateRequest>({
      resource: "club-management",
      action: "edit",
      id: "",
      dataProviderName: "clubManagementDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Руководство клуба 2.0 успешно создано",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const onSubmitForm = () => {
    const values = form.getFieldsValue(true);
    onFinish({ ...values });
  };

  return (
    <Show
      isLoading={formLoading}
      title={false}
      breadcrumb={false}
      headerButtons={<Space></Space>}
      footerButtons={null}
      goBack={false}
    >
      <Form {...formProps} onFinish={onSubmitForm} layout={"vertical"}>
        <Row gutter={24}>
          <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <ClubManagementFileCreate form={form} />
          </Space>
          <Space style={{ marginTop: "25px" }}>
            <Button type={"primary"} htmlType={"submit"}>
              Сохранить
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
