import { Tabs, TabsProps } from "antd";
import { List } from "@refinedev/antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ClubManagementShow } from "./Club-Management-Show";
import { ClubManagementDocsList } from "./ClubManagementDocsList";

export function ClubManagementPage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get("tab") || "content";

  const onChange = (key: string) => {
    navigate(
      { search: createSearchParams({ tab: key }).toString() },
      { replace: true }
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "content",
      label: "Контент",
      children: <ClubManagementShow />,
    },
    {
      key: "docs",
      label: "Документы",
      children: <ClubManagementDocsList />,
    },
  ];

  return (
    <List
      title="Руководство клуба 2.0"
      resource={"docs"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Tabs items={items} onChange={onChange} activeKey={tabKey} />
    </List>
  );
}
