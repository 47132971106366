import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import ru_RU from "antd/es/locale/ru_RU";

import { ThemedLayoutV2, useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import "suneditor/dist/css/suneditor.min.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import routerProvider, {
  NavigateToResource,
  CatchAllNavigate,
} from "@refinedev/react-router-v6";
import { App as AntdApp, ConfigProvider } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { CustomSider } from "./layout/sider";

import { Login } from "./pages/Login/login.page";

import dataProvider from "@refinedev/simple-rest";

import { authProvider, ShowProvider } from "./providers";
import {
  CLUBS_ROUTES,
  clubsAchievementsDataProvider,
  clubsColorDataProvider,
  clubsDataProvider,
  clubsDescriptionDataProvider,
  clubsMediaDataProvider,
  clubsResource,
  clubsTagsDataProvider,
  HASH_TAGS_ROUTES,
  hashTagsDataProvider,
  hashTagsResource,
  MATCHES_ROUTES,
  matchesDataProvider,
  matchesMediaDataProvider,
  matchesResource,
  NEWS_ROUTES,
  newsDataProvider,
  newsResource,
  seasonsDataProvider,
  seasonsResourceClubsLeagueGenerator,
  seasonsResourceLeagueGenerator,
  toursDataProvider,
  translationsDataProvider,
  socialLinksDataProvider,
  USERS_ROUTES,
  LEAGUES_ROUTES,
  usersDataProvider,
  usersResource,
  leaguesResource,
  aboutResource,
  employeesResource,
  leagueInfoDataProvider,
  groupsDataProvider,
  mappingResource,
  melbetResource,
  MAPPING_ROUTES,
  mappingMelbetDataProvider,
  rusStatResource,
  mappingRusstatDataProvider,
  partnersResource,
  supportMessagesResource,
  usersImportResource,
  USERS_IMPORT_ROUTES,
  pushNotificationResource,
  PUSH_NOTIFICATION_ROUTES,
  pushNotificationDataProvider,
  usersImportDataProvider,
  PARTNERS_ROUTES,
  SUPPORT_MESSAGES_ROUTES,
  partnersDataProvider,
  whereToLookDataProvider,
  supportMessagesDataProvider,
  rusStatClubsResource,
  rusStatPlayersResource,
  clubsBackgroundDataProvider,
  mappingAutoRusstatPlayersDataProvider,
  whereToLookResource,
  lookResourceLeagueGenerator,
  WHERE_TO_LOOK_ROUTES,
  employeesPresidiumResource,
  employeesTeamResource,
  socialResponsibilityResource,
  docsResource,
  presentationsResource,
  philosophyResource,
  partnerResource,
  clubManagementResource,
} from "./pages";
import { RedirectPage } from "./pages/Redirect";
import { uploadDataProvider } from "./providers/uploadDataProvider";
import { newsPinDataProvider } from "./pages/News/Providers/news-pin-data-provider";
import { mappingRusstatClubsDataProvider } from "./pages/Mapping/Russtat/Providers/mapping-russtat-clubs-data-provider";
import { mappingRusstatPlayersDataProvider } from "./pages/Mapping/Russtat/Providers/mapping-russtat-players-data-provider";
import { employeesPresidiumDataProvider } from "pages/Leagues/Providers/employees-presidium-data-provider";
import { employeesTeamDataProvider } from "pages/Leagues/Providers/employees-team-data-provider";
import { departmentsTeamDataProvider } from "pages/Leagues/Providers/departments-team-data-provider";
import { socialResponsibilityDataProvider } from "pages/Leagues/Providers/social-responsibility-data-provider";
import { docsDataProvider } from "pages/Leagues/Providers/docs-data-provider";
import { presentationsDataProvider } from "pages/Leagues/Providers/presentations-data-provider";
import { philosophyDataProvider } from "pages/Leagues/Providers/philosophy-data-provider";
import { clubManagementDataProvider } from "pages/Leagues/Providers/club-management-data-provider";
import { partnerDataProvider } from "pages/Leagues/Providers/partner-data-provider";
import { clubsStadiumDataProvider } from "pages/Clubs/Providers/clubs-stadium-data-provider";
import { clubsPhotoStadiumDataProvider } from "pages/Clubs/Providers/clubs-photo-stadium-data-provider";
import { clubsTrophiesDataProvider } from "pages/Clubs/Providers/clubs-trophies-data-provider";
import { subsectionsDocsDataProvider } from "pages/Leagues/Providers/subsections-docs-data-provider";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ru");

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ConfigProvider locale={ru_RU}>
          <AntdApp>
            <Refine
              authProvider={authProvider}
              notificationProvider={useNotificationProvider}
              routerProvider={routerProvider}
              dataProvider={{
                default: dataProvider("https://api.fake-rest.refine.dev"),
                uploadDataProvider: uploadDataProvider(""),
                matchesDataProvider: matchesDataProvider("/cms/calendar"),
                matchesMediaDataProvider: matchesMediaDataProvider(""),
                seasonsDataProvider: seasonsDataProvider(""),
                newsDataProvider: newsDataProvider(""),
                newsPinDataProvider: newsPinDataProvider(""),
                usersDataProvider: usersDataProvider(""),
                toursDataProvider: toursDataProvider(""),
                groupsDataProvider: groupsDataProvider(""),
                translationsDataProvider: translationsDataProvider(""),
                socialLinksDataProvider: socialLinksDataProvider(""),
                usersImportDataProvider: usersImportDataProvider(""),
                pushNotificationDataProvider: pushNotificationDataProvider(""),
                clubsDataProvider: clubsDataProvider("/cms/info/teamsBySeason"),
                clubsMediaDataProvider: clubsMediaDataProvider(""),
                clubsColorDataProvider: clubsColorDataProvider(""),
                subsectionsDocsDataProvider: subsectionsDocsDataProvider(""),
                clubsAchievementsDataProvider:
                  clubsAchievementsDataProvider(""),
                employeesPresidiumDataProvider:
                  employeesPresidiumDataProvider(""),
                socialResponsibilityDataProvider:
                  socialResponsibilityDataProvider(""),
                philosophyDataProvider: philosophyDataProvider(""),
                clubManagementDataProvider: clubManagementDataProvider(""),
                clubsStadiumDataProvider: clubsStadiumDataProvider(""),
                partnerDataProvider: partnerDataProvider(""),
                docsDataProvider: docsDataProvider(""),
                presentationsDataProvider: presentationsDataProvider(""),
                employeesTeamDataProvider: employeesTeamDataProvider(""),
                clubsPhotoStadiumDataProvider:
                  clubsPhotoStadiumDataProvider(""),
                departmentsTeamDataProvider: departmentsTeamDataProvider(""),
                clubsDescriptionDataProvider: clubsDescriptionDataProvider(""),
                clubsTrophiesDataProvider: clubsTrophiesDataProvider(""),
                hashTagsDataProvider: hashTagsDataProvider(""),
                clubsTagsDataProvider: clubsTagsDataProvider(""),
                leagueInfoDataProvider: leagueInfoDataProvider(""),
                mappingMelbetDataProvider: mappingMelbetDataProvider(""),
                mappingRusstatDataProvider: mappingRusstatDataProvider(""),
                mappingRusstatClubsDataProvider:
                  mappingRusstatClubsDataProvider(""),
                mappingRusstatPlayersDataProvider:
                  mappingRusstatPlayersDataProvider(""),
                partnersDataProvider: partnersDataProvider(""),
                whereToLookDataProvider: whereToLookDataProvider(""),
                supportMessagesDataProvider: supportMessagesDataProvider(""),
                clubsBackgroundDataProvider: clubsBackgroundDataProvider(""),
                mappingAutoRusstatPlayersDataProvider:
                  mappingAutoRusstatPlayersDataProvider(""),
              }}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                reactQuery: {
                  devtoolConfig: false,
                  clientConfig: {
                    defaultOptions: {
                      queries: {
                        staleTime: Infinity,
                        retry: false,
                        refetchOnWindowFocus: false,
                        keepPreviousData: true,
                      },
                    },
                  },
                },
              }}
              resources={[
                usersResource,
                hashTagsResource,
                leaguesResource,
                aboutResource,
                employeesResource,
                employeesPresidiumResource,
                employeesTeamResource,
                socialResponsibilityResource,
                docsResource,
                presentationsResource,
                philosophyResource,
                partnerResource,
                clubManagementResource,
                matchesResource,
                seasonsResourceLeagueGenerator("first-league"),
                seasonsResourceLeagueGenerator("second-league"),
                seasonsResourceLeagueGenerator("third-league"),
                newsResource,
                clubsResource,
                seasonsResourceClubsLeagueGenerator("first-league"),
                seasonsResourceClubsLeagueGenerator("second-league"),
                seasonsResourceClubsLeagueGenerator("third-league"),
                mappingResource,
                melbetResource,
                rusStatResource,
                rusStatClubsResource,
                whereToLookResource,
                lookResourceLeagueGenerator("first-league"),
                lookResourceLeagueGenerator("second-league"),
                lookResourceLeagueGenerator("third-league"),
                partnersResource,
                supportMessagesResource,
                usersImportResource,
                pushNotificationResource,
                rusStatPlayersResource,
              ]}
            >
              <Routes>
                {/*Некий контент*/}
                <Route
                  element={
                    <Authenticated
                      key="authenticated-layout"
                      fallback={<CatchAllNavigate to="/login" />}
                      v3LegacyAuthProviderCompatible
                    >
                      <ThemedLayoutV2 Sider={() => <CustomSider />}>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    path="/"
                    index
                    element={
                      <ShowProvider>
                        <RedirectPage />
                      </ShowProvider>
                    }
                  />
                  {USERS_ROUTES}
                  {MATCHES_ROUTES}
                  {NEWS_ROUTES}
                  {CLUBS_ROUTES}
                  {HASH_TAGS_ROUTES}
                  {LEAGUES_ROUTES}
                  {MAPPING_ROUTES}
                  {PARTNERS_ROUTES}
                  {SUPPORT_MESSAGES_ROUTES}
                  {USERS_IMPORT_ROUTES}
                  {PUSH_NOTIFICATION_ROUTES}
                  {WHERE_TO_LOOK_ROUTES}
                </Route>

                {/*Авторизация*/}
                <Route
                  element={
                    <Authenticated
                      fallback={<Outlet />}
                      v3LegacyAuthProviderCompatible
                    >
                      <NavigateToResource resource="matches" />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>
              {/*<UnsavedChangesNotifier*/}
              {/*  message={"Вы уверены что хотите выйти? Есть несохраненные данные"}*/}
              {/*/>*/}
              {/*<DocumentTitleHandler />*/}
            </Refine>
          </AntdApp>
        </ConfigProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
