import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import dayjs from "dayjs";
import { dateFormat, DayMonthYear } from "helpers";
import { SubsectionDocType } from "../Lib";

export const presentationsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      variables,
    }: {
      resource: string;
      variables: any;
    }) => {
      const url = "cms/league/documents/section";
      try {
        const { data } = await httpClient.get(url, {
          params: { sectionId: -4 },
        });
        return { data: data.documents };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: any;
      meta: {
        fileUrl: string;
      };
    }) => {
      const { fileUrl } = meta;

      try {
        const res = await httpClient.post("cms/league/documents", {
          title: variables.title,
          file: fileUrl,
          icon: variables.icon,
          date: dateFormat(variables.date, DayMonthYear),
        });

        await httpClient.post("cms/league/documents/add/section", {
          sectionId: -4,
          docId: res.data,
          ord: Number(variables.ord),
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/documents/id";

      try {
        const { data } = await httpClient.get(url, {
          params: { docId: id },
        });
        data.date = dayjs(data.date, DayMonthYear);

        const { data: dataSection } = await httpClient.get(
          "cms/league/documents/section",
          { params: { sectionId: -4 } }
        );
        const ord = dataSection.documents.find(
          (presentation: SubsectionDocType) => presentation.id == id
        ).ord;
        data.ord = ord;
        // const oneResource = data.find(
        //   (item: LeagueStaffType) => item.deptId == id
        // );
        return { data: data };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    update: async ({
      resource,
      variables,
      meta,
      id,
    }: {
      id: number;
      resource: string;
      variables: any;
      meta: {
        fileUrl: string;
      };
    }) => {
      const { fileUrl } = meta;

      try {
        const res = await httpClient.put("cms/league/documents", {
          id: id,
          title: variables.title,
          file: fileUrl,
          icon: variables.icon,
          date: dateFormat(variables.date, DayMonthYear),
        });

        await httpClient.post("cms/league/documents/add/section", {
          sectionId: -4,
          docId: id,
          ord: Number(variables.ord),
        });

        return { data: res };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/documents";
      try {
        const { data } = await httpClient.delete(url, {
          params: { docId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
