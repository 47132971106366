import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { ClubManagementCreateRequest } from "../Lib/types";

export const clubManagementDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getOne: async ({ resource, id }) => {
      try {
        const { data: response } = await httpClient.get("/cms/league/info/2b");
        const data = await { ...response };
        return { data };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      variables,
    }: {
      id: string;
      resource: string;
      variables: ClubManagementCreateRequest;
    }) => {
      const url = "/cms/league/info/2b";
      const { content } = variables;

      const contentResult: any[] = [];
      content.map((elem: any) => {
        if (!elem.media?.type) elem.media = null;
        if (!elem.text && !elem.media) elem = null;
        if (elem) contentResult.push(elem);
      });
      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          content: contentResult,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
