import { Outlet, Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { LeaguesList } from "../Leagues-List";
import { EmployeeShow } from "../Employees/Presidium/EmployeeShow";
import { EmployeeCreate } from "../Employees/Presidium/EmployeeCreate";
import { FNLPresidium } from "../Employees/Presidium/FNL-Presidium";
import { FNLTeam } from "../Employees/Team/FNL-Team";
import { EmployeeTeamCreate } from "../Employees/Team/EmployeeTeamCreate";
import { DepartmentsShow } from "../Employees/Team/DepartmentsShow";
import { DepartmentsCreate } from "../Employees/Team/DepartmentsCreate";
import { EmployeeTeamShow } from "../Employees/Team/EmployeeTeamShow";
import { SocialResponsibilityList } from "../Social-Responsibility/SocialResponsibilityList";
import { SocialShow } from "../Social-Responsibility/SocialShow";
import { SocialCreate } from "../Social-Responsibility/SocialCreate";
import { DocsCreate } from "../Docs/DocsCreate";
import { PhilosophyShow } from "../Philosophy/Philosophy-Show";
import { PhilosophyEdit } from "../Philosophy/Philosophy-Edit";
import { PartnerShow } from "../Partners/Partners-Show";
import { PartnerEdit } from "../Partners/Partners-Edit";
import { DocsPage } from "../Docs/DocsPage";
import { SubsectionCreate } from "../Docs/SubsectionCreate";
import { SubsectionShow } from "../Docs/SubsectionShow";
import { ClubManagementEdit } from "../Club-Management/Club-Management-Edit";
import { ClubManagementPage } from "../Club-Management/ClubManagementPage";
import { PresentationsList } from "../Presentations/PresentationsList";
import { PresentationsShow } from "../Presentations/PresentationsShow";
import { PresentationsCreate } from "../Presentations/PresentationsCreate";

const leaguesRoutes = [
  <Route key={"league-info"} path="league-info" element={<Outlet />}>
    <Route
      key={"about"}
      path="about"
      element={
        <ShowProvider>
          <LeaguesList />
        </ShowProvider>
      }
    />
    <Route key={"employees"} path="employees" element={<Outlet />}>
      <Route
        key={"presidium"}
        path="presidium"
        element={
          <ShowProvider>
            <FNLPresidium />
          </ShowProvider>
        }
      />
      <Route
        key={"team"}
        path="team"
        element={
          <ShowProvider>
            <FNLTeam />
          </ShowProvider>
        }
      />
    </Route>
    <Route
      key={"presidiumShow"}
      path="employees/presidium"
      element={<Outlet />}
    >
      <Route
        key={"presidiumShowId"}
        path="show/:id"
        element={
          <ShowProvider>
            <EmployeeShow />
          </ShowProvider>
        }
      />
      <Route
        key={"presidiumCreate"}
        path="create"
        element={
          <ShowProvider>
            <EmployeeCreate />
          </ShowProvider>
        }
      />
    </Route>
    <Route key={"teamShow"} path="employees/team" element={<Outlet />}>
      <Route
        key={"presidiumShowId"}
        path="show-employee/:id"
        element={
          <ShowProvider>
            <EmployeeTeamShow />
          </ShowProvider>
        }
      />
      <Route
        key={"employeeTeamCreate"}
        path="create-employee"
        element={
          <ShowProvider>
            <EmployeeTeamCreate />
          </ShowProvider>
        }
      />
    </Route>
    <Route key={"teamShowDepts"} path="employees/team" element={<Outlet />}>
      <Route
        key={"presidiumShowId"}
        path="show-dept/:id"
        element={
          <ShowProvider>
            <DepartmentsShow />
          </ShowProvider>
        }
      />
      <Route
        key={"employeeTeamCreate"}
        path="create-dept"
        element={
          <ShowProvider>
            <DepartmentsCreate />
          </ShowProvider>
        }
      />
    </Route>
    <Route
      key={"social-responsibility"}
      path="social-responsibility"
      element={<Outlet />}
    >
      <Route
        key={"social-responsibility-list"}
        path=""
        element={
          <ShowProvider>
            <SocialResponsibilityList />
          </ShowProvider>
        }
      />
      <Route
        key={"social-responsibility-show"}
        path="show/:id"
        element={
          <ShowProvider>
            <SocialShow />
          </ShowProvider>
        }
      />
      <Route
        key={"social-responsibility-create"}
        path="create"
        element={
          <ShowProvider>
            <SocialCreate />
          </ShowProvider>
        }
      />
    </Route>
    <Route key={"docs"} path="docs" element={<Outlet />}>
      <Route
        key={"docs-list"}
        path=""
        element={
          <ShowProvider>
            <DocsPage />
          </ShowProvider>
        }
      />
      <Route
        key={"docs-create"}
        path="create"
        element={
          <ShowProvider>
            <DocsCreate />
          </ShowProvider>
        }
      />

      <Route
        key={"subsection-docs-create"}
        path="subsection-docs-create"
        element={
          <ShowProvider>
            <SubsectionCreate />
          </ShowProvider>
        }
      />
      <Route
        key={"subsection-docs-show"}
        path="subsection-docs-show/:id"
        element={
          <ShowProvider>
            <SubsectionShow />
          </ShowProvider>
        }
      />
    </Route>

    <Route key={"presentations"} path="presentations" element={<Outlet />}>
      <Route
        key={"presentations-list"}
        path=""
        element={
          <ShowProvider>
            <PresentationsList />
          </ShowProvider>
        }
      />
      {/* <Route
        key={"docs-create"}
        path="create"
        element={
          <ShowProvider>
            <DocsCreate />
          </ShowProvider>
        }
        /> */}

      <Route
        key={"presentations-create"}
        path="create"
        element={
          <ShowProvider>
            <PresentationsCreate />
          </ShowProvider>
        }
      />
      <Route
        key={"presentations-show"}
        path="show/:id"
        element={
          <ShowProvider>
            <PresentationsShow />
          </ShowProvider>
        }
      />
    </Route>

    <Route key={"philosophy"} path="philosophy" element={<Outlet />}>
      <Route
        key={"philosophy-show"}
        path=""
        element={
          <ShowProvider>
            <PhilosophyShow />
          </ShowProvider>
        }
      />

      <Route
        key={"philosophy-edit"}
        path="edit"
        element={
          <ShowProvider>
            <PhilosophyEdit />
          </ShowProvider>
        }
      />
    </Route>

    <Route key={"partner"} path="partner" element={<Outlet />}>
      <Route
        key={"partner-show"}
        path=""
        element={
          <ShowProvider>
            <PartnerShow />
          </ShowProvider>
        }
      />

      <Route
        key={"partner-edit"}
        path="edit"
        element={
          <ShowProvider>
            <PartnerEdit />
          </ShowProvider>
        }
      />
    </Route>
    <Route key={"club-management"} path="club-management" element={<Outlet />}>
      <Route
        key={"club-management-show"}
        path=""
        element={
          <ShowProvider>
            <ClubManagementPage />
          </ShowProvider>
        }
      />

      <Route
        key={"club-management-edit"}
        path="edit"
        element={
          <ShowProvider>
            <ClubManagementEdit />
          </ShowProvider>
        }
      />
    </Route>
  </Route>,
];

export const LEAGUES_ROUTES = leaguesRoutes.map((route) => route);
