import { EditButton, Show, useForm } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../helpers";

import { Form, Input, Row, Space } from "antd";
import { AppImageUploader, EFileTypes } from "../../../../components";
import { v4 as randomId } from "uuid";
import { ClubManagementBlockCreate } from "../Club-Management-Create/Club-Management-Block-Create";
import { ClubManagementCreateRequest } from "../../Lib/types";
import dayjs from "dayjs";
import { useNavigation } from "@refinedev/core";

export const ClubManagementShow = () => {
  const { push } = useNavigation();

  const { formProps, form, formLoading } = useForm<ClubManagementCreateRequest>(
    {
      resource: "club-management",
      action: "edit",
      id: "",
      dataProviderName: "clubManagementDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Руководство клуба 2.0 успешно создано",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    }
  );

  return (
    <Show
      isLoading={formLoading}
      title={false}
      breadcrumb={false}
      headerButtons={
        <Space direction={"horizontal"}>
          <EditButton onClick={() => push("/league-info/club-management/edit")}>
            Редактировать
          </EditButton>
        </Space>
      }
      footerButtons={null}
      goBack={false}
    >
      <Form
        {...formProps}
        initialValues={{
          newsDate: dayjs(),
          ...formProps.initialValues,
          leagueId:
            formProps.initialValues?.leagueId === null
              ? 0
              : formProps.initialValues?.leagueId,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <Form.Item
              name={"mainPhotoUrl"}
              label={"Главное фото"}
              rules={[
                {
                  required: true,
                  message: "Укажите изображение",
                },
              ]}
            >
              <AppImageUploader
                type="NEWS"
                disabled
                entityId={`${randomId()}`}
                serviceType={EFileTypes.NEWS}
                cropperProps={{
                  modalTitle: "Редактировать изображение",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Изображение Руководства клуба 2Б х МРО",
                  listType: "picture-card",
                }}
              />
            </Form.Item>

            <Form.Item label="Заголовок" name={"title"}>
              <Input disabled type="text" placeholder={"Заголовок не указан"} />
            </Form.Item>

            <ClubManagementBlockCreate setDisabled={true} form={form} />
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
