import { Show, useForm } from "@refinedev/antd";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { v4 as randomId } from "uuid";

import { errorNotification, successNotification } from "../../../helpers";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { SubsectionDocType } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";
import { useList, useNavigation } from "@refinedev/core";

export const SubsectionCreate = () => {
  const { push } = useNavigation();

  const { data, isLoading, refetch } = useList<SubsectionDocType[]>({
    resource: "subsectionsDocs",
    meta: {},
    dataProviderName: "subsectionsDocsDataProvider",
    ...errorNotification(),
  });

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<SubsectionDocType>({
      resource: "subsectionsDocs",
      action: "create",
      meta: { deptsData: data?.data },
      dataProviderName: "subsectionsDocsDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Подразделение добавлено",
          type: "success",
        },
        callback: () => push("/league-info/docs?tab=SubsectionsList"),
      }),
      ...errorNotification({}),
    });

  return (
    <Show
      title={<AppTitle canGoBack>Добавить подраздел</AppTitle>}
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={"icon"}>
              <AppImageUploader
                type="STAFF"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать иконку",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Иконка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"title"}
              label={"Наименование подраздела:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо наименование подраздела ",
                },
                {
                  max: 100,
                  min: 3,
                  message: "Не более 100 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите наименование подраздела "}></Input>
            </Form.Item>
            <Form.Item
              name={"ord"}
              label={"Порядок отображения подраздела"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать порядок отображения подраздела",
                },
              ]}
            >
              <Input
                placeholder={"Укажите порядок отображения подраздела"}
                type="number"
              ></Input>
            </Form.Item>
            <Form.Item
              name={"parentId"}
              label={"Корневой раздел"}
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать корневой раздел",
                },
              ]}
            >
              <Select
                placeholder="Выберите корневой раздел"
                notFoundContent={"Корневой раздел не найден"}
                allowClear
              >
                <Select.Option key={"-30"} value={-30}>
                  Документы ФНЛ
                </Select.Option>
                <Select.Option key={"-31"} value={-31}>
                  Документы РФС
                </Select.Option>
                <Select.Option key={"-32"} value={-32}>
                  Документы МЕЛБЕТ 1-я лига
                </Select.Option>
                <Select.Option key={"-33"} value={-33}>
                  Документы LEON 2-я лига А
                </Select.Option>
                <Select.Option key={"-34"} value={-34}>
                  Документы LEON 2-я лига Б
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"description"}
              label={"Описание:"}
              style={{ width: "100%" }}
            >
              <Input.TextArea
                rows={10}
                placeholder={"Укажите описание"}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"}>
              {"Добавить подраздел"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
