import { ResourceProps } from "@refinedev/core";
import { TrophyOutlined, UserOutlined } from "@ant-design/icons";

export const leaguesResource: ResourceProps = {
  name: "league-info",
  meta: {
    icon: <TrophyOutlined />,
    label: "О лиге",
  },
};

export const aboutResource: ResourceProps = {
  name: "about",
  list: "/league-info/about",
  meta: {
    parent: "league-info",
    label: "Описание лиги",
  },
};

export const employeesResource: ResourceProps = {
  name: "employees",
  meta: {
    parent: "league-info",
    label: "Сотрудники",
    icon: <UserOutlined />,
  },
};
export const employeesPresidiumResource: ResourceProps = {
  name: "presidium",
  list: "/league-info/employees/presidium",
  meta: {
    parent: "employees",
    label: "Президиум",
  },
};
export const employeesTeamResource: ResourceProps = {
  name: "team",
  list: "/league-info/employees/team",
  meta: {
    parent: "employees",
    label: "Команда",
  },
};

export const socialResponsibilityResource: ResourceProps = {
  name: "social-responsibility",
  list: "/league-info/social-responsibility",
  meta: {
    parent: "league-info",
    label: "Социальная ответственность",
  },
};

export const docsResource: ResourceProps = {
  name: "docs",
  list: "/league-info/docs",
  meta: {
    parent: "league-info",
    label: "Документы",
  },
};

export const presentationsResource: ResourceProps = {
  name: "presentations",
  list: "/league-info/presentations",
  meta: {
    parent: "league-info",
    label: "Отчетные презентации",
  },
};

export const philosophyResource: ResourceProps = {
  name: "philosophy",
  list: "/league-info/philosophy",
  meta: {
    parent: "league-info",
    label: "Философия",
  },
};

export const partnerResource: ResourceProps = {
  name: "partner",
  list: "/league-info/partner",
  meta: {
    parent: "league-info",
    label: "Партнерам",
  },
};
export const clubManagementResource: ResourceProps = {
  name: "club-management",
  list: "/league-info/club-management",
  meta: {
    parent: "league-info",
    label: "Руководство клуба 2.0",
  },
};
