import { ShowButton, useTable, List, TextField } from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Space, Table } from "antd";

import { AppImagePreview } from "components";
import { SubsectionsLinksDelete } from "./SubsectionsLinksDelete";
import { SubsectionDocType } from "../Lib";

export function SubsectionsList() {
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<any[]>({
    dataProviderName: "subsectionsDocsDataProvider",
    resource: "subsectionsDocs",
    meta: {},
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleNavigateToEdit = (id: string) => {
    push(`/league-info/docs/subsection-docs-show/${id}`);
  };

  return (
    <List
      title={""}
      resource={"subsectionsDocs"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Подразделы отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<SubsectionDocType>
          dataIndex={""}
          width={250}
          key="icon"
          title={"Иконка подраздела"}
          render={(value) => {
            return <AppImagePreview imageURL={value.icon} useS3={true} />;
          }}
        />
        <Table.Column<SubsectionDocType>
          width={250}
          dataIndex={"title"}
          key="title"
          title="Наименование подраздела"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SubsectionDocType>
          dataIndex={"description"}
          key="description"
          title="Описание"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SubsectionDocType>
          dataIndex={"parent"}
          key="parent"
          title="Корневой раздел"
          render={(value: any) => (
            <span>
              {value.parentTitle} (id: {value.parentId})
            </span>
          )}
        />
        <Table.Column<SubsectionDocType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToEdit(String(record.id))}
                size={"small"}
                block
              >
                Смотреть / Редактировать
              </ShowButton>
              <SubsectionsLinksDelete
                id={Number(record.id)}
                name={record.title}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
