export async function recursiveUprateOrd(
  ord: number,
  elemsArr: any[],
  callBack: (data: any) => void
): Promise<undefined> {
  const elem = elemsArr.find((elem) => elem.ord == ord);
  if (elem) {
    const newOrd = elem.ord + 1;
    await callBack({ ...elem, ord: newOrd });
    return recursiveUprateOrd(newOrd, elemsArr, callBack);
  } else {
    return undefined;
  }
}

export function getAllDepts(depts: any[]) {
  const result: any[] = [];

  depts.forEach((dept) => {
    result.push(dept);
    if (dept.childDept.length) {
      result.push(...getAllDepts(dept.childDept));
    }
  });
  return result;
}
export function getAllSubsections(subsections: any[]) {
  const result: any[] = [];

  subsections.forEach((subsection) => {
    const childDept = subsection.childDept.map((dept: any) => ({
      parent: {
        parentTitle: subsection.title,
        parentId: subsection.id,
      },
      ...dept,
    }));
    result.push(...childDept);
  });
  return result;
}
