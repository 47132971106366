import {
  useTable,
  List,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Space, Table } from "antd";

import { SubsectionDocType } from "../Lib";
import { LinksDelete } from "./LinksDelete";
import { s3Path } from "helpers";
import { useContext } from "react";
import { EnvContext } from "providers";
import { useNavigation } from "@refinedev/core";
import { AppImagePreview } from "components";

export function PresentationsList() {
  const { THUMBOR_URL } = useContext(EnvContext);
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<SubsectionDocType[]>({
    dataProviderName: "presentationsDataProvider",
    resource: "presentations",
    meta: {},
    syncWithLocation: false,
    queryOptions: { enabled: true },
  });

  const handleNavigateToEdit = (id: string) => {
    push(`/league-info/presentations/show/${id}`);
  };
  const handleNavigateToCreate = () => {
    push("/league-info/presentations/create");
  };

  return (
    <List
      title={"Отчетные презентации"}
      resource={"presentations"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton onClick={() => handleNavigateToCreate()}>
          Добавить презентацию
        </CreateButton>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Ссылки отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<SubsectionDocType>
          dataIndex={"title"}
          key="title"
          title="Наименование"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SubsectionDocType>
          dataIndex={""}
          width={250}
          key="icon"
          title={"Фото-обложка"}
          render={(value) => {
            return <AppImagePreview imageURL={value.icon} useS3={true} />;
          }}
        />
        <Table.Column<SubsectionDocType>
          dataIndex={"ord"}
          key="ord"
          title="Порядок отображения"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SubsectionDocType>
          dataIndex={""}
          width={300}
          key="file"
          title={"Файл"}
          render={(value) => {
            return (
              <a
                href={s3Path({
                  path: value.file,
                  original: true,
                  thumbor: THUMBOR_URL,
                })}
                target="_blank"
                rel="noreferrer"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {value.file.replace(/.*\//, "")}
              </a>
            );
          }}
        />

        <Table.Column<SubsectionDocType>
          dataIndex={"date"}
          key="date"
          title="Дата"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SubsectionDocType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToEdit(String(record.id))}
                size={"small"}
                block
              >
                Смотреть / Редактировать
              </ShowButton>
              <LinksDelete id={Number(record.id)} name={record.title} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
