import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const docsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: any;
      meta: { sectionId: number };
    }) => {
      const url = "cms/league/documents/section";
      try {
        const { data: sections } = await httpClient.get(
          "cms/league/document/sections"
        );
        const mainSection = sections.find(
          (section: any) => section.id == meta.sectionId
        );
        const childSections = mainSection.childDept;

        const res = await Promise.all(
          [mainSection, ...childSections].map(async (section: any) => {
            const { data } = await httpClient.get(url, {
              params: { sectionId: section.id },
            });
            data.documents.forEach(
              (document: any) =>
                (document.parent = { id: section.id, title: section.title })
            );
            return data.documents;
          })
        );
        const flatRes = res.flat();

        return {
          data: flatRes,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: any;
      meta: {
        fileUrl: string;
      };
    }) => {
      const { title } = variables;
      const { fileUrl } = meta;

      try {
        const res = await httpClient.post("cms/league/documents", {
          title,
          file: fileUrl,
        });

        await httpClient.post("cms/league/documents/add/section", {
          sectionId: variables.sectionId,
          docId: res.data,
          ord: Number(variables.ord),
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/documents";
      try {
        const { data } = await httpClient.delete(url, {
          params: { docId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
