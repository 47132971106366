import { useTable, List, TextField } from "@refinedev/antd";
import { Space, Table } from "antd";

import { LeagueSocialType } from "../Lib";
import { LinksDelete } from "./LinksDelete";
import { s3Path } from "helpers";
import { useContext } from "react";
import { EnvContext } from "providers";
import { useNavigation } from "@refinedev/core";

export function DocsList({ sectionId }: { sectionId: number }) {
  const { THUMBOR_URL } = useContext(EnvContext);
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<LeagueSocialType[]>({
    dataProviderName: "docsDataProvider",
    resource: "docs",
    meta: { sectionId: sectionId },
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleNavigateToEdit = (id: string) => {
    push(`/league-info/docs/show/${id}`);
  };

  return (
    <List
      title={""}
      resource={"docs"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Ссылки отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<LeagueSocialType>
          dataIndex={""}
          width={300}
          key="file"
          title={"Файл"}
          render={(value) => {
            return (
              <a
                href={s3Path({
                  path: value.file,
                  original: true,
                  thumbor: THUMBOR_URL,
                })}
                target="_blank"
                rel="noreferrer"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {value.file.replace(/.*\//, "")}
              </a>
            );
          }}
        />
        <Table.Column<LeagueSocialType>
          dataIndex={"title"}
          key="title"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueSocialType>
          dataIndex={"parent"}
          key="parent"
          title="Раздел/Подраздел"
          render={(value: any) => (
            <span>
              {value.title} (id: {value.id})
            </span>
          )}
        />
        <Table.Column<LeagueSocialType>
          width={200}
          dataIndex={"ord"}
          key="ord"
          title="Порядок отображения в подразделе"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueSocialType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <LinksDelete id={Number(record.id)} name={record.title} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
