import { Button, Card, Form, FormInstance, Space, Input, Upload } from "antd";

import { DeleteOutlined } from "@ant-design/icons";
import s from "./Club-Management-Create.module.scss";
import { FC, useContext } from "react";
import axAPI from "../../../../api/axios-instance";
import { UploadOutlined } from "@ant-design/icons";
import { s3Path } from "helpers";
import { EnvContext } from "providers";

type NewsBlockCreateProps = { form: FormInstance<any> };

export const ClubManagementFileCreate: FC<NewsBlockCreateProps> = ({
  form,
}) => {
  const { THUMBOR_URL } = useContext(EnvContext);

  async function uploadFile(file: File, type: string) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const result = await axAPI.post("/cms/image/upload/type", formData, {
        params: { type },
      });
      return Promise.resolve({ s3id: result.data });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  function getFile(index: number) {
    const fields = form.getFieldsValue();
    const { documents } = fields;
    return documents?.[index]?.file;
  }

  return (
    <Space size={16} direction="vertical" style={{ width: "100%" }}>
      <Form.List name="documents">
        {(fields, { add, remove }) => (
          <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
            {!fields.length && "Нет загруженных документов"}
            {fields.map((field, index) => {
              return (
                <Card
                  key={field.key}
                  title={
                    <div className={s.newsBlockHeader}>
                      {getFile(index)?.replace(/.*\//, "")}
                      {
                        <div
                          className={s.appImageDelete}
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          <DeleteOutlined style={{ color: "var(--danger)" }} />
                        </div>
                      }
                    </div>
                  }
                >
                  <Form.Item
                    label="Наименование документа"
                    rules={[
                      {
                        required: true,
                        message: "Укажите Наименование документа",
                      },
                      {
                        min: 5,
                        max: 100,
                        message:
                          "Наименование документа может быть от 5 до 100 символов",
                      },
                    ]}
                    name={[field.name, "title"]}
                  >
                    <Input
                      type="text"
                      placeholder={"Укажите Наименование документа"}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "ord"]}
                    label={"Порядок отображения"}
                    rules={[
                      {
                        required: true,
                        message: "Необходимо указать порядок отображения ",
                      },
                    ]}
                  >
                    <Input
                      placeholder={"Укажите порядок отображения"}
                      type="number"
                    ></Input>
                  </Form.Item>

                  <Form.Item
                    name={[field.name, "tempFile"]}
                    style={{ maxWidth: "500px", width: "100%" }}
                  >
                    {getFile(index) ? (
                      <a
                        href={s3Path({
                          path: getFile(index),
                          original: true,
                          thumbor: THUMBOR_URL,
                        })}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                      >
                        {getFile(index).replace(/.*\//, "")}
                      </a>
                    ) : (
                      <Upload
                        customRequest={(e: any) => {
                          uploadFile(e.file as File, "DOCUMENTS").then(
                            (res) => {
                              const fields = form.getFieldsValue();
                              const { documents } = fields;
                              Object.assign(documents[field.key], {
                                file: res.s3id,
                              });
                              form.setFieldsValue({ documents });
                              e.onSuccess("ok");
                            }
                          );
                        }}
                        accept=".pdf"
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "file"]}
                    rules={[{ required: true, message: "Загрузите документ" }]}
                    style={{ marginTop: "-20px", marginBottom: "-20px" }}
                  >
                    <Input type="text" style={{ visibility: "hidden" }}></Input>
                  </Form.Item>
                </Card>
              );
            })}
            <button onClick={() => add()}>Добавить файл</button>
          </Space>
        )}
      </Form.List>
    </Space>
  );
};
