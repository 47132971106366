import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { LeagueDeptType } from "../Lib";
import { getAllSubsections, recursiveUprateOrd } from "../Lib/helpers";

export const subsectionsDocsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/document/sections";

      try {
        const { data } = await httpClient.get(url);

        return {
          data: getAllSubsections(data || []),
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/document/sections/item";

      try {
        const { data } = await httpClient.get(url, {
          params: { sectionId: id },
        });
        // const oneResource = data.find(
        //   (item: LeagueStaffType) => item.deptId == id
        // );
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      meta: {
        deptsData: LeagueDeptType[];
      };
      variables: any;
    }) => {
      const { ord, ...otherVariables } = variables;
      const { deptsData } = meta;
      const deptsDataFilteredByParentId = deptsData.filter(
        (dept) => dept.parentId == variables.parentId
      );
      const url = "cms/league/document/sections";

      try {
        await httpClient.post(url, {
          ...otherVariables,
          ord: Number(ord),
        });
        await recursiveUprateOrd(
          variables.ord,
          deptsDataFilteredByParentId,
          async (data) => await httpClient.put(url, data)
        );
        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
        deptsData: LeagueDeptType[];
      };
      variables: any;
    }) => {
      const url = "cms/league/document/sections";

      const { id, deptsData } = meta;

      const deptsDataFilteredByParentId = deptsData.filter(
        (dept) => dept.parentId == variables.parentId
      );
      const currentIndex = deptsDataFilteredByParentId.findIndex(
        (dept) => dept.id == id
      );
      if (currentIndex !== -1)
        deptsDataFilteredByParentId.splice(currentIndex, 1);

      try {
        const { data } = await httpClient.put(url, { ...variables, id });
        await recursiveUprateOrd(
          variables.ord,
          deptsDataFilteredByParentId,
          async (data) => await httpClient.put(url, data)
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/document/sections";
      try {
        const { data } = await httpClient.delete(url, {
          params: { sectionId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
