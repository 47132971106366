import { Tabs, TabsProps } from "antd";
import { CreateButton, List } from "@refinedev/antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PartnersList } from "./Partners-List";

export const PartnersTabs = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get("tab") || "fnl";

  const onChange = (key: string) => {
    navigate(
      { search: createSearchParams({ tab: key }).toString() },
      { replace: true }
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "fnl",
      label: "Лига ФНЛ",
      children: <PartnersList leagueId={null} />,
    },
    {
      key: "first",
      label: "Первая лига",
      children: <PartnersList leagueId={100} />,
    },
    {
      key: "second-a",
      label: "Вторая лига А",
      children: <PartnersList leagueId={200} />,
    },
    {
      key: "second-b",
      label: "Вторая лига Б",
      children: <PartnersList leagueId={300} />,
    },
  ];

  return (
    <List
      title="Партнеры"
      resource={"partners"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton resource={"partners"}>Добавить партнера</CreateButton>
      }
    >
      <Tabs items={items} onChange={onChange} activeKey={tabKey} />
    </List>
  );
};
