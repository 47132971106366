import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const pushNotificationDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    custom: async ({ meta, url }) => {
      try {
        const [
          { data: activeSeasonMelbet },
          { data: activeSeasonLeonA },
          { data: activeSeasonLeonB },
        ] = await Promise.all([
          httpClient.get("cms/info/allActiveSeason?leagueId=100"),
          httpClient.get("cms/info/allActiveSeason?leagueId=200"),
          httpClient.get("cms/info/allActiveSeason?leagueId=300"),
        ]);
        const [{ data: groupInfoLeonA }, { data: groupInfoLeonB }] =
          await Promise.all([
            httpClient.get("cms/info/groupsBySeason", {
              params: {
                leagueId: 200,
                seasonId: activeSeasonLeonA[0].seasonId,
              },
            }),
            httpClient.get("cms/info/groupsBySeason", {
              params: {
                leagueId: 300,
                seasonId: activeSeasonLeonB[0].seasonId,
              },
            }),
          ]);

        return {
          data: {
            activeSeasonInfo: {
              activeSeasonMelbet,
              activeSeasonLeonA,
              activeSeasonLeonB,
            },
            groupInfo: { groupInfoLeonA, groupInfoLeonB },
          },
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({ meta, variables }: any) => {
      try {
        const { data } = await httpClient.post(
          "cms/notification/send",
          variables
        );

        return { data };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
