import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";

import { PushNotificationContent } from "../PushNotificationContent/Push-Notification-Content";

const pushNotificationRoutes = [
  <Route
    key={"push-notification"}
    path="push-notification"
    element={
      <ShowProvider>
        <PushNotificationContent />
      </ShowProvider>
    }
  />,
];

export const PUSH_NOTIFICATION_ROUTES = pushNotificationRoutes.map(
  (route) => route
);
