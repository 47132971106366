import { Tabs, TabsProps } from "antd";
import { DocsList } from "./DocsList";
import { useState } from "react";

export function DocsTabs() {
  // const navigate = useNavigate();
  const [tabKey, setTabkey] = useState("fnl");

  const onChange = (key: string) => {
    setTabkey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "fnl",
      label: "ФНЛ",
      children: <DocsList sectionId={-30} />,
    },
    {
      key: "rfs",
      label: "РФС",
      children: <DocsList sectionId={-31} />,
    },
    {
      key: "melbet",
      label: "МЕЛБЕТ 1-я лига",
      children: <DocsList sectionId={-32} />,
    },
    {
      key: "leon-a",
      label: "LEON 2-я лига А",
      children: <DocsList sectionId={-33} />,
    },
    {
      key: "leon-b",
      label: "LEON 2-я лига Б",
      children: <DocsList sectionId={-34} />,
    },
  ];

  return <Tabs items={items} onChange={onChange} activeKey={tabKey} />;
}
