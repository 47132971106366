import { ResourceProps } from "@refinedev/core";
import { NotificationOutlined } from "@ant-design/icons";

export const pushNotificationResource: ResourceProps = {
  name: "push-notification",
  meta: {
    icon: <NotificationOutlined />,
    label: "Отправка push-уведомления",
  },
  list: "/push-notification",
};
