import { FC } from "react";
import { useModal } from "@refinedev/antd";
import { useDelete } from "@refinedev/core";
import { errorNotification, successNotification } from "../../../helpers";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

type NewsDeleteProps = {
  id: number;
  name: string;
};

export const SubsectionsLinksDelete: FC<NewsDeleteProps> = ({ id, name }) => {
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName: "subsectionsDocsDataProvider",
      resource: "subsectionsDocs",
      id,
      meta: {
        id,
      },
      ...successNotification({
        successData: {
          message: `«${name}» успешно удален`,
          type: "success",
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      <Button
        size={"small"}
        danger
        onClick={show}
        block
        icon={<DeleteOutlined />}
      >
        Удалить
      </Button>

      <Modal
        okText={"Удалить"}
        cancelText={"Отмена"}
        onOk={handleDelete}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить «{name}»?
      </Modal>
    </>
  );
};
