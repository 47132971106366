import { Create, useForm } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../helpers";

import { Button, Form, Input, Row, Space } from "antd";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../../components";
import { v4 as randomId } from "uuid";
import { ClubManagementBlockCreate } from "../Club-Management-Create/Club-Management-Block-Create";
import { ClubManagementCreateRequest } from "../../Lib/types";

export const ClubManagementEdit = () => {
  const { formProps, form, formLoading, onFinish } =
    useForm<ClubManagementCreateRequest>({
      resource: "club-management",
      action: "edit",
      id: "",
      dataProviderName: "clubManagementDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Руководство клуба 2.0 успешно отредактировано",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const onSubmitForm = () => {
    const values = form.getFieldsValue(true);
    onFinish({ ...values });
  };

  return (
    <Create
      isLoading={formLoading}
      title={
        <AppTitle
          isLoading={formLoading}
          canGoBack
          routerLink={"/league-info/club-management"}
        >
          Редактирование руководства клуба 2Б х МРО
        </AppTitle>
      }
      breadcrumb={false}
      headerButtons={<></>}
      footerButtons={<></>}
      goBack={false}
    >
      <Form {...formProps} layout={"vertical"} onFinish={onSubmitForm}>
        <Row gutter={24}>
          <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <Form.Item
              name={"mainPhotoUrl"}
              label={"Изображение"}
              rules={[
                {
                  required: true,
                  message: "Укажите изображение",
                },
              ]}
            >
              <AppImageUploader
                type="NEWS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.NEWS}
                cropperProps={{
                  modalTitle: "Редактировать изображение",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Изображение",
                  listType: "picture-card",
                }}
              />
            </Form.Item>

            <Form.Item label="Заголовок" name={"title"}>
              <Input type="text" placeholder={"Укажите заголовок"} />
            </Form.Item>

            <ClubManagementBlockCreate form={form} />

            <Space>
              <Button type={"primary"} htmlType={"submit"}>
                Отредактировать
              </Button>
            </Space>
          </Space>
        </Row>
      </Form>
    </Create>
  );
};
